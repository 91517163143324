/**
 * src/js/news-slider.js
 *
 * Show images as Overlay
 *
 * @author Carsten Coull <c.coull@agentur-halma.de>
 * @package data-al
 */

function NewsSlider() {

	var self = this;

	self.init = function() {
		if ($('.news-slider-wrapper').length != 0) {
			self.setup();
		}
	};

	self.setup = function () {

		$('.news-slider-wrapper').flickity({
			// options
			cellAlign: 'left',
			contain: true,
			autoPlay: false,
			wrapAround: true,
			adaptiveHeight: true
		});
	};
}
