/**
 * src/js/map.js
 *
 * Handle interactive map for "service" page ("Technische Partner")
 *
 * @author Johanens Braun <j.braun@agentur-halma.de>
 * @package data-al
 */

function InteractiveMap() {

	var self = this;

	var highlightClass;

	var selectedClass;

	var $legend;

	this.init = function() {
		
		if (document.querySelector('table.states')) {
			// Test for IE11 https://stackoverflow.com/a/19868056
			if (!(window.ActiveXObject) && "ActiveXObject" in window) {
				document.querySelector('table.states').style.display = 'table';
				return;
			}

			self.highlightClass = 'is-highlighted';
			self.selectedClass = 'is-selected';

			// Load map
			var $injectMap = $('#js-inject-map');
			if ($injectMap.length > 0) {

				console.log("InteractiveMap::init");
				$.get('?action=getMap&tid=8', function(response) {
					$injectMap.html(response);
					self.setup();
				});
			}
		}
	}


	this.setup = function() {

		self.$legend = $('#js-map-legend');

		var items = document.querySelectorAll('.states .state');
		var shapes = document.querySelectorAll('#map > .st0');

		function dehighlightAll () {
			items.forEach (function (el) {
				el.classList.remove (self.highlightClass);
			});
			shapes.forEach (function (el) {
				el.classList.remove (self.highlightClass);
			});
		}

		function unselectAll() {
			shapes.forEach(function(el) {
				el.classList.remove(self.selectedClass);
			});
		}

		function selectFromShape(shape) {
			shape.classList.add(self.selectedClass);
		}

		function highlightFromShape (el) {
			var stateId = el.getAttribute ('data-state');
			var shape = document.getElementById (stateId);
			el.classList.add (self.highlightClass);
			shape.classList.add (self.highlightClass);
		}

		function highlightFromItem (el) {
			var stateId = el.getAttribute ('id');
			var item = document.querySelector ('[data-state="' + stateId + '"]');
			el.classList.add (self.highlightClass);
			item.classList.add (self.highlightClass);
		}

		items.forEach(function(el) {
			el.addEventListener('mouseenter', function () {
				highlightFromShape(el);
			});

			el.addEventListener('mouseleave', function () {
				dehighlightAll();
			});
		});

		shapes.forEach (function (shape) {
			shape.addEventListener ('mouseenter', function () {
				highlightFromItem (shape);
			});
			shape.addEventListener ('mouseleave', function () {
				dehighlightAll ();
			});
			shape.addEventListener ('click', function () {

				// Select shape
				unselectAll();
				selectFromShape(this);
				// Update legend
				var stateId = this.id;
				self.$legend.on('transitionend', function() {
					self.showPartnersForState(stateId);
					self.$legend.removeClass('fade-out');
				});
				self.$legend.addClass('fade-out');

			})
		});



		this.showPartnersForState = function(stateId) {
			var $tr = $('[data-state=' + stateId + ']');
			var state = $tr.find('th').html();
			var $list = $tr.next().find('.partners__list');

			self.$legend.first().empty();
			self.$legend.first().append($('<h3>' + state + '<h3>'));
			self.$legend.first().append($list.clone());
		}


		// Preselect BW
		var stateId = 'baden-wuerttemberg';
		if (window.location.hash) {
			stateId = window.location.hash.substr(1);
		}
    
    var exeption = ['section-1', 'section-2', 'Partnerfirmen', 'Technische-Partner'];
    for (var i = 0; i < exeption.length; i++) {
      if (stateId == exeption[i]) {
        stateId = 'baden-wuerttemberg';
      }
    }

		var shape = document.getElementById(stateId);
		dehighlightAll();
		unselectAll();
		selectFromShape(shape);
		self.showPartnersForState(stateId);
	}
}

window.addEventListener('load', function () {
	var map = new InteractiveMap();
	map.init();

});
