var Infographic = {

	init: function () {

		// ---------------------------------------------

        var labels = document.querySelectorAll ('#Leistungen > g');
        labels.forEach (function (label) {
            label.style.opacity = 0;
            label.style.transition = 'opacity 100ms ease-in-out';
        });

		var circles = document.querySelectorAll ('.circle');
		circles.forEach (function (circle, i) {
			// var circle = s.circlePath (450, 450, 360 - (i * padding));
			// circle.style.stroke = "#da144f";
			// circle.style.strokeWidth = "2px";
			var len = circle.getTotalLength ();
			circle.style.strokeDasharray = len + "px " + len + "px";
			circle.style.strokeDashoffset = len + "px";
            circle.addEventListener ('transitionend', function (ev) {
                var dot = this.parentNode.querySelector ('.dot');
                dot.style.opacity = 1;
				if (labels.length > i) {
					labels[i].style.opacity = 1;
				}
            });
		});

        var dots = document.querySelectorAll ('.dot');
        dots.forEach (function (dot) {
            dot.style.opacity = 0;
            dot.transition = 'opacity 100ms ease-in';
        });

		// Start animation when scrolling into view
		var ctl = new ScrollMagic.Controller ();
		var scene = new ScrollMagic.Scene ({
			triggerElement: '.solutions',
			triggerHook: 0.5
		})
		.addTo (ctl)
		.on ("enter", startAnimation)
		;



		function startAnimation () {
			circles.forEach (function (circle, i) {
				circle.style.transitionDelay = (i * 100) + "ms";
				circle.style.transitionDuration = "0.350s";
				circle.style.transitionTimingFunction = "ease-in-out";
                circle.style.strokeDashoffset = 0;
				// var len = circle.getTotalLength ();
				// var targetLength = len * (0.25 + (i/8 * 0.5));

				// circle.style.strokeDashoffset = (len - targetLength) + "px";

			});
		}
	}
};
