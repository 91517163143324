/**
 * src/js/main.js
 *
 * main javascript file
 *
 * @author Johannes Braun <j.braun@agentur-halma.de>
 * @package data-al
 */
function APP () {

	var self = this;
	
	var lightBox =  new LightBox;
	var newsSlider =  new NewsSlider;

	self.debug = !window.location.hostname.match(/data-al\.de/);

    /**
     * @var Object
     * ScrollMagic main controller
     */
    var ctl;

	this.init = function () {
		
		if (this.debug) {
			console.log ('APP::init');
		}
		
		self.ctl = new ScrollMagic.Controller ();

		this.pageInit();
		
		
	};

	this.pageInit = function() {

		if (this.debug) {
			console.log('APP::pageInit');
		}

		document.body.classList.add('page-has-loaded');
		this.main();
		lightBox.init();
		newsSlider.init();
	};



    this.initAnimations = function () {

        var els = document.querySelectorAll ('.cards');
        if (els) {
            var tween = new TweenMax.staggerFromTo ('.card', 0.5, 
                {
                    opacity: 0,
                    y: 75
                },
                {
                    opacity: 1,
                    y: 0
                },
                0.4
            );
            var scene = new ScrollMagic.Scene ({
                triggerElement: els,
                triggerHook: 1,
                duration: 400
            })
            .setTween (tween)
            .addTo (self.ctl);
        }


		// Stagger the plus chain
        var el = document.querySelector('.plus-chain');
        if (el) {
            var tween = new TweenMax.staggerFromTo ('.plus-chain__item', .5,
                {
                    opacity: 0,
                    scale: 0
                },
                {
                    opacity: 1,
                    scale: 1,
                },
                0.25
            );
            var scene = new ScrollMagic.Scene ({
                triggerElement: el,
                triggerHook: 0.9,
                duration: 0
            });
            scene.setTween (tween)
            scene.addTo (self.ctl);
        }


        var el = document.querySelector  ('.video-container');
        if (el) {
            var appearTween = new TweenMax.fromTo (el, 1, { scale: 0, opacity: 0.15}, { scale: 1, opacity: 1});
            var scene = new ScrollMagic.Scene ({
                triggerElement: el,
                triggerHook: 1
            })
            .setTween (appearTween)
            .addTo (self.ctl);
        }

        var el = document.querySelector ('.testimonials-container');
        if (el) {
            var appearTween = new TweenMax.fromTo (el, 1, { scale: 0, opacity: 0.15}, { scale: 1, opacity: 1});
            var scene = new ScrollMagic.Scene ({
                triggerElement: el,
                triggerHook: 1
            })
            .setTween (appearTween)
            .addTo (self.ctl);
        }

		var el = document.querySelectorAll('.framed-carousel');
		if (el) {
			var appearTween = new TweenMax.fromTo(el, 0.5, { y: 150}, {y: 0});
			var scene = new ScrollMagic.Scene({
				triggerElement: el,
				triggerHook: 0.75
			})
			.setTween(appearTween)
			.addTo(self.ctl);
		}

		var el = document.querySelector('.needs');
		if (el) {
            var tween = new TweenMax.staggerFrom('.need', 0.3,
                {
					cycle: {
						x: [ 1000, -1000 ],
						scale: [ 0, 0 ]
					}
                },
                0.3
            );
            var scene = new ScrollMagic.Scene ({
                triggerElement: el,
                triggerHook: 0.75,
                duration: 0
            });
            scene.setTween(tween)
            scene.addTo(self.ctl);
		}
    };




    // Uses https://inorganik.github.io/countUp.js/
    this.statsCounter = function () {

        var options = {
            useEasing: true,
            useGrouping: true,
            separator: '',
            decimal: ''
        };
        var counters = [];
        document.querySelectorAll ('.stat').forEach (function (stat) {
            var el = stat.querySelector ('em, i');
            var value = parseInt (el.innerHTML);
            counters.push (new CountUp (el, 0, value, 0, 2, options));
        });

        var scene = new ScrollMagic.Scene({
            triggerElement: '.stats',
            triggerHook: 1
        })
        .addTo (self.ctl)
        .on ('enter', function () {
            counters.forEach (function (counter) {
                counter.start (function () {
                    document.querySelectorAll('.stat').forEach (function (el) {
                        el.classList.add ('counter-finished');
                    });
                });
            });
        });
    };

	this.main = function() {

        // Set correct max-height values for collapsable tables
        $('.collapsable-table').each (function () {
            var $el = $(this);
            var h = $el.height ();
            var $body = $el.find ('.collapsable-table__body');
            $body.height (h);
            $el.addClass ('collapsable-table--is-collapsed');

            $el.on ('click', function () {
                $(this).toggleClass ('collapsable-table--is-collapsed');
            });
        });


		// Wait until all images are loaded
		window.onload = function () {
			$testimonials = $('.testimonials');
			if ($testimonials.length > 0) {
				$testimonials.flickity({
					cellAlign: 'left',
					contain: true,
					setGallerySize: true,
					wrapAround: true,
					arrowShape: 'M 60.998,18.799 34.254,50 60.998,81.201 65.742,77.133 42.486,50 65.742,22.867 60.998,18.799 Z'
				});
			}

			$galleryCarousel = $('.gallery.gallery--carousel');
			if ($galleryCarousel.length > 0) {
				$galleryCarousel.flickity({
					cellAlign: 'left',
					// contain: true,
					// setGallerySize: true,
					wrapAround: true,
					autoPlay: 6000,
					arrowShape: 'M 60.998,18.799 34.254,50 60.998,81.201 65.742,77.133 42.486,50 65.742,22.867 60.998,18.799 Z'
				});
			}
		};


        self.initAnimations ();
        self.statsCounter ();


		// Menu: Handle Drop-Down
		$elementsWithDrillDown = $('.mobnav__row--has-drill-down');
		$elementsWithDrillDown.on('click', function (ev) {
			$elementsWithDrillDown.removeClass('is-visible');
            $(this).toggleClass ('is-visible');
        });

		// Click anywhere outside the dropdown to close it
		$(document).on('click', function(ev) {
			$target = $(ev.target);
			if ($target.closest('.mobnav__page').length == 0) {
				$elementsWithDrillDown.removeClass('is-visible');
			}
		});


		// Smooth scroll to CTA when clicking the eyecatcher
		$('.eyecatcher a').on('click', function(ev) {
			$target = $($(this).attr('href'));
			$('html,body').animate({
				scrollTop: $target.offset().top
			}, 1000);
		});
	};
};



document.addEventListener('DOMContentLoaded', function() {
	var app = new APP();
	app.init();
});

