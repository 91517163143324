/**
 * mobnav.js
 * 
 * @author Johannes Braun <j.braun@agentur-halma.de>
 * @package mobnav
 * @varsion 2016-08-24
 */

var MOBNAV = {

	init: function () {

		var i;
		var drillDownButtons = document.querySelectorAll('.mobnav__button--drill-down');
		var drillUpButtons = document.querySelectorAll('.mobnav__row--drill-up');
		var closeButtons = document.querySelectorAll('.mobnav__button--close');

        drillDownButtons.forEach (function (btn) {
            btn.addEventListener ('click', MOBNAV.onDrillDown);
        });

        drillUpButtons.forEach (function (btn) {
            btn.addEventListener ('click', MOBNAV.onDrillUp);
        });
        closeButtons.forEach (function (btn) {
            btn.addEventListener ('click', MOBNAV.onClose);
        });

        // Animating the hamburger icon and toggle body class
        document.getElementById ('js-mobnav-trigger').addEventListener ('click', function () {
            // Toggle body class
            document.body.classList.toggle ('menu-is-open');
            // Animate paths of hamburger SVG
            this.querySelectorAll('animate').forEach(function(el){
                var oldTo = el.getAttribute ('to');
                el.setAttribute ('to', el.getAttribute ('from'));
                el.setAttribute ('from', oldTo);
                el.beginElement ();
            });
        });
	},

	onDrillDown: function(event) {
		this.parentNode.classList.add('mobnav__page--is-active');
		//$('.mobnav').scrollTop(0); // How to do this without jQuery??
	},

	onDrillUp: function(event) {
		this.parentNode.parentNode.classList.remove('mobnav__page--is-active');
	},

	onClose: function(event) {
		var mobnav = document.getElementById('mobnav-trigger');
		mobnav.checked = false;
	}
};

document.addEventListener('DOMContentLoaded', MOBNAV.init);
