/**
 * src/js/lightbox.js
 *
 * Show images as Overlay
 *
 * @author Carsten Coull <c.coull@agentur-halma.de>
 * @package data-al
 */

function LightBox() {

	var self = this;

	self.init = function() {
		if ($('#js-lightbox').length != 0) {
			self.setup();
		}
	};

	self.setup = function () {
		var count = 0;
		var overlay;
		
		$('.lightbox-image').each(function(index, el) {
			$(this).attr('data-lightbox', count++); // set image counter
		});
		
		$('.lightbox-image').click(function(event) {
			if ($('#js-lightbox-overlay').length != 0) { // lightbox element exists
				$('.js-lightbox-overlay__image').attr('src', $(this).attr('src'));
				$('.js-lightbox-overlay__image').attr('data-current-image', $(this).attr('data-lightbox'));
			} else { // create lightbox elment
				var newOverlay = $('<div id="js-lightbox-overlay"><img class="js-lightbox-overlay__image" width="400" height="300" src="" data-current-image=""><button class="js-lightbox-overlay__button" type="button" name="close">Close</button><button class="js-lightbox-overlay__button" type="button" name="previous">Previous Image</button><button class="js-lightbox-overlay__button" type="button" name="next">Next Image</button></div>');
				$('#js-lightbox').after(newOverlay);
				overlay = $('#js-lightbox-overlay');
				
				setImage($(this));
				
				$('button[name="close"]').focus();
				
				$('button[name="next"]').click(function(event) {
					var currentImageId = $('.js-lightbox-overlay__image').attr('data-current-image');
					currentImageId++;
					if (currentImageId >= count) {
						currentImageId = 0;
					}
					
					var currentImage = $('[data-lightbox="' + currentImageId + '"]');
					setImage(currentImage);
				});
				
				$('button[name="previous"]').click(function(event) {
					var currentImageId = $('.js-lightbox-overlay__image').attr('data-current-image');
					currentImageId--;
					
					if (currentImageId <= 0) {
						currentImageId = count -1;
					}
					
					var currentImage = $('[data-lightbox="' + currentImageId + '"]');
					setImage(currentImage);
				});
				
				$('button[name="close"]').click(function(event) {
					$('#js-lightbox-overlay').remove();
				});
			}
		});
	};
	
	function setImage(id) { // set image
		$('.js-lightbox-overlay__image').attr('src', id.attr('src'));
		$('.js-lightbox-overlay__image').attr('data-current-image', id.attr('data-lightbox'));
	}
}